
<template>
     <!-- This is main routing page. Never shown itself -->

</template>


<script setup>
    const { $get } = useNuxtApp();
    if(process.client) {
        const token =  sessionStorage.getItem("token")
        const invitation_id = sessionStorage.getItem("invitation_id")
        const router = useRouter();

        
        

        if (token == null) {
            navigateTo('/login')
        } else {
            let {$parseJwt} = useNuxtApp()
            const parsedToken = $parseJwt(token);
            
            if (invitation_id){
                navigateTo('/invitations')
            } else {
            
                if (parsedToken.membership.length == 0) {
                    navigateTo('/welcome')
                } else {
                    // if no callcenters are created we redirect to create callcenter page
                    let callcenterObject = await $get("/api/protected/callcenter");
                    if (callcenterObject.data.length == 0){
                        router.push({ path: `callcenter/create` });
                    } else {
                        router.push({ path: `company` });
                    }
                    //navigateTo('/company')
                }
            }
        }
    }
</script>